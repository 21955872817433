@font-face {
  font-family: "Potta";
  src: url("./PottaOne-Regular.ttf");
}
body {
  margin-top: 50px;
}
.App {
  text-align: center;
}
.container {
  height: auto;
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  padding: 0%;
  background-color: white;
  margin: 0%;
}
.navigation-bar {
  height: auto;
  /* animation: 1s ease-in 0s 1 slideFromLeft; */
}
.navbar {
  position: absolute;
  background-color: #1f2833;
  width: 100%;
}

.navbar li a {
  color: white;
}
.navbar li:hover {
  background-color: #66fcf1;
}
.Introduction-container {
  height: auto;
  display: flex;
  flex-flow: row wrap;
}
.NameHeader {
  padding: 12%;
  width: 100%;
  height: auto;
  background-image: url("main-background.jpg");
  background-attachment: fixed;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-align: center;
}
.upper {
  width: 80%;
  size: 10;
  animation: 1s ease-in 0s 1 slideFromLeft;
}
.NameHeader h1 {
  font-size: 8vw;
  animation: 1s ease-in 0s 1 slideFromRight;
  color: white;
}
.NameHeader h2 {
  font-size: 4vw;
  animation: 1s ease-in 0s 1 slideFromRight;
  color: white;
}
.Information {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}
.NameHeader-Information {
  width: 100%;
}
@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.Content {
  margin-top: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  height: 50%;
}
.Image {
  display: flex;
  width: 100%;
  height: 60%;
  justify-content: center;
}
.Image img {
  height: 100%;
  width: 300px;
  border-radius: 20%;
}

.aboutMe {
  display: flex;
  width: 100%;
  height: 40%;
  color: #1f2833;
  justify-content: center;
  text-align: center;
  flex-flow: column wrap;
}
.aboutMe h1 {
  font-family: Arial, Helvetica, sans-serif;
}
.aboutMe h1::first-letter {
  text-decoration: none;
}
.paragraph {
  padding: 2%;
  font-size: large;
}
.connections {
  display: flex;
  text-align: center;
  justify-content: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-image: url("./components/s.jpg");
  background-size: auto auto;
  height: auto;
  width: 100%;
  color: white;
  flex-flow: column wrap;
}
.connections .header {
  width: 100%;
  color: white;
  height: auto;
}
.icons {
  padding: 5%;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  color: white;
  font-size: xx-large;
  height: auto;
}
.icons .facebook {
  width: 25%;
  color: white;
}
.icons .github {
  width: 25%;
  color: white;
}
.icons .linkedin {
  width: 25%;
  color: white;
}
.icons .instagram {
  width: 25%;
  color: white;
}
.social {
  color: white;
  font-size: 50px;
}

.EmotionalPolarity {
  padding-top: 3%;
  margin: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}
.sideBar {
  width: 0%;
  flex-shrink: 40;
}
.projectContent {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  height: 100%;
}
.innerContent {
  flex-grow: 0.4;
  height: 512px;
  padding: 2%;
  width: 400px;
}
.innerContent iframe {
  width: 100%;
  height: 450px;
}
.ProjectHeading {
  text-align: center;
}
@media only screen and (max-width: 500px) {
  .innerContent {
    height: 356px;
  }
  .innerContent iframe {
    height: 220px;
  }
}
.Form {
  display: flex;
  flex-flow: column wrap;
  padding: 2%;
  width: 100%;
  align-items: center;
  height: 100%;
  background-color: #1f2833;
  background-attachment: fixed;
}
.Form div {
  margin-top: 10px;
  display: flex;
  flex-flow: column wrap;
}
.Form .formHeading {
  text-align: center;
  font-family: "Potta";
  color: #66fcf1;
}
.Form .DisplayMessage {
  text-align: center;
  font-family: "Potta";
  color: #66fcf1;
  font-size: 20px;
}
@media only screen and (min-height: 700px) {
  .Form {
    height: 100vh;
  }
}
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* .projectContent {
  height: 35vw;
  width: 4vw;
  flex-grow: 1;
  padding: 4%;
  padding-top: 10%;
  height: auto;
}
.projectContent h1 {
  font-size: 45px;
  color: #1f2833;
  text-shadow: 1.5px 1.5px gray;
  text-align: left;
}
.projectContent p {
  padding-right: 2%;
  font-size: 20px;
  text-align: justify;
}
.projectContent iframe {
  width: 100%;
  height: 50vh;
}
.sideBar {
  width: 8vw;
} */
